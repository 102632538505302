import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loading from "./components/Loading";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
  })
  .init({
    supportedLngs: ["en", "cs"],
    fallbackLng: "cs",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
