import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Pages.css";
import data from "../assets/projects-db.json";
import ProjectGallery from "../components/ProjectGallery/ProjectGallery";
import PortfolioCta from "../components/PortfolioCta";

const Project = (props) => {
  document.title = props.title;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <header className="gallery-header">
        <h1 className="gallery-title">{props.title}</h1>
        <ul className="tags">
          {props.tags.map((tag, i) => (
            <li key={i}>{tag}</li>
          ))}
        </ul>
      </header>
      <ProjectGallery data={data} slug={props.slug} />
      <PortfolioCta link={"/our-work"} title={t("show_more")} />
    </>
  );
};

export default Project;
