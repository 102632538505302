import "./PromoBox.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
var imgixProps = "?auto=format,compress&w=1920&q=75&dpi=72";

const PromoBox = ({ title, promoType, source, tags, slug }) => {
  const { t } = useTranslation();

  return (
    <Link className="promo" to={"/our-work/" + slug}>
      <article>
        {promoType === "video" ? (
          <Vimeo
            video={source}
            loop
            muted={true}
            background={true}
            autoplay
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            className={"media-wrapper"}
          />
        ) : (
          <div className="media-wrapper">
            <img
              src={
                "https://penactdigital.imgix.net/" +
                source +
                (promoType !== "gif" ? imgixProps : "")
              }
              alt={title + " - " + t("thumb")}
            />
          </div>
        )}
        <div className="info">
          <h3>{title}</h3>
          <ul>
            {tags.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
        </div>
      </article>
    </Link>
  );
};

PromoBox.propTypes = {
  title: PropTypes.string.isRequired,
  promoType: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
};

export default PromoBox;
