import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Career = () => {
  const { t } = useTranslation();

  document.title = t("kariera_title");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <article className="aboutBox">
        <h1>{t("position_01_title")}</h1>
        <div className="bulleted-list">
          <p>{t("position_01_desc")}</p>
          <br />
          <p>
            <strong>{t("position_demands_title")}</strong>
          </p>
          <ul>
            <li>{t("position_01_demands_01")}</li>
            <li>{t("position_01_demands_02")}</li>
            <li>{t("position_01_demands_03")}</li>
            <li>{t("position_01_demands_04")}</li>
            <li>{t("position_01_demands_05")}</li>
            <li>{t("position_01_demands_06")}</li>
          </ul>
          <br />
          <p>
            <strong>{t("position_offer_title")}</strong>
          </p>
          <ul>
            <li>{t("position_01_offer_01")}</li>
            <li>{t("position_01_offer_02")}</li>
            <li>{t("position_01_offer_03")}</li>
            <li>{t("position_01_offer_04")}</li>
          </ul>
          <br />
          <p>
            <strong>{t("portfolio_cta")}</strong>
            <br></br>
            <a href="mailto:talent@penactdigital.com">
              talent@penactdigital.com
            </a>
          </p>
        </div>
      </article>
      <article className="aboutBox">
        <h1>{t("position_02_title")}</h1>
        <div className="bulleted-list">
          <p>{t("position_02_desc")}</p>
          <br />
          <p>
            <strong>{t("position_demands_title")}</strong>
          </p>
          <ul>
            <li>{t("position_02_demands_01")}</li>
            <li>{t("position_02_demands_02")}</li>
            <li>{t("position_02_demands_03")}</li>
            <li>{t("position_02_demands_04")}</li>
            <li>{t("position_02_demands_05")}</li>
            <li>{t("position_02_demands_06")}</li>
          </ul>
          <br />
          <p>
            <strong>{t("position_offer_title")}</strong>
          </p>
          <ul>
            <li>{t("position_02_offer_01")}</li>
            <li>{t("position_02_offer_02")}</li>
            <li>{t("position_02_offer_03")}</li>
          </ul>
          <br />
          <p>
            <strong>{t("portfolio_cta")}</strong>
            <br></br>
            <a href="mailto:talent@penactdigital.com">
              talent@penactdigital.com
            </a>
          </p>
        </div>
      </article>
    </>
  );
};

export default Career;
