import "./Pages.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  document.title = t("o_nas");

  return (
    <>
      <article className="aboutBox">
        <h1>{t("about_title")}</h1>
        <p>{t("about_perex")}</p>
      </article>

      <article className="aboutBox">
        <h2>{t("about_skills")}</h2>
        <ul>
          <li>ux/ui design</li>
          <li>{t("graphic_design")}</li>
          <li>{t("branding")}</li>
          <li>{t("print")}</li>
          <li>motion design</li>
          <li>{t("dev")}</li>
          <li>ecommerce</li>
          <li>{t("webdesign")}</li>
          <li>{t("apps")}</li>
          <li>{t("internal")}</li>
        </ul>
      </article>

      <article className="aboutBox">
        <h3>{t("contact")}</h3>
        <ul>
          <li>
            <li>
              <strong>{t("letsgo")}</strong>
            </li>
            <a href="mailto:hello@penactdigital.com">hello@penactdigital.com</a>
          </li>
          <br />
          <li>
            <strong>{t("address_title")}</strong>
          </li>
          <li>
            <address>
              penact s.r.o.
              <br />
              Orelská 513&#47;5,&nbsp;Vršovice,
              <br />
              101 00 Praha&nbsp;10
            </address>
          </li>
          <li>{t("ico")} 09959980</li>
        </ul>
      </article>
    </>
  );
};

export default AboutPage;
