import React from "react";

class ScrollDetect extends React.Component {
  isBottom(el) {
    return (
      el.getBoundingClientRect().bottom <=
      document.querySelector("nav").offsetHeight
    );
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.querySelector("main");
    const nav = document.querySelector("nav");
    if (this.isBottom(wrappedElement)) {
      nav.classList.add("inverted");
    } else {
      nav.classList.remove("inverted");
    }
  };

  render() {
    return <></>;
  }
}

export default ScrollDetect;
