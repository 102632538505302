import "./Layout.css";
import { useEffect, useState } from "react";
import { Outlet, Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../components/logo.svg";
import { ReactComponent as LogoIcon } from "../assets/svg/logo-icon.svg";
import ScrollDetect from "../assets/tools/ScrollDetect";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "EN",
    label: "English",
    country_code: "en",
  },
  {
    code: "cs",
    name: "CZ",
    label: "Čeština",
    country_code: "cs",
  },
];

function Layout() {
  const currentLanguageCode = cookies.get("i18next") || "cs";

  const { t } = useTranslation();

  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  const [isInverted, setIsInverted] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const invertedRoutes = ["/about-us", "/career"];
    setIsInverted(invertedRoutes.includes(location.pathname));
  }, [location.pathname]);

  const containerClass = isInverted ? "container invert" : "container";

  return (
    <div className={containerClass}>
      <nav id={sidebar ? "is-open" : ""} className="">
        <div className="wrapper">
          <Link
            exact="true"
            to="/"
            className="logo-box"
            onClick={sidebar ? showSidebar : undefined}
            aria-label="Go to homepage"
          >
            <Logo />
            <LogoIcon />
          </Link>
          <button className="menu-toggle" onClick={showSidebar}>
            Menu
          </button>
          <ul className="navList">
            <li>
              <NavLink
                className="nav-item"
                activeclassname="active"
                to="/our-work"
                onClick={sidebar ? showSidebar : undefined}
                title={t("nase_prace")}
              >
                {t("nase_prace")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-item"
                activeclassname="active"
                to="/about-us"
                onClick={sidebar ? showSidebar : undefined}
                title={t("o_nas")}
              >
                {t("o_nas")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-item"
                activeclassname="active"
                to="/career"
                onClick={sidebar ? showSidebar : undefined}
                title={t("kariera")}
              >
                {t("kariera")}
              </NavLink>
            </li>
            <li className="lang">
              {languages.map(({ code, name, country_code, label }) => (
                <button
                  key={country_code}
                  className="nav-item"
                  onClick={() => i18next.changeLanguage(code)}
                  disabled={code === currentLanguageCode}
                  title={label}
                >
                  {name}
                </button>
              ))}
            </li>
          </ul>
          <ul className="nav-footer">
            <strong className="nav-footer-title">Pojďme na to</strong>
            <a href="mailto:hello@penactdigital.com" className="nav-footer-cta">
              hello@penactdigital.com
            </a>
            <ul className="social">
              <li>
                <a
                  href="https://instagram.com/penactdigital"
                  target="_blank"
                  rel="noopenen noreferrer"
                >
                  instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/penact-digital/"
                  target="_blank"
                  rel="noopenen noreferrer"
                >
                  linkedIn
                </a>
              </li>
            </ul>
          </ul>
        </div>
      </nav>

      <main>
        <div className="wrapper">
          <Outlet />
        </div>
      </main>

      <footer>
        <div className="wrapper">
          <div className="about">
            <h1 className="footerTitle">{t("footer_title")}</h1>
            <a href="mailto:hello@penactdigital.com" className="mailTo">
              hello@penactdigital.com
            </a>
          </div>
          <div className="bottom">
            <p className="copyright">
              &copy;&nbsp;{new Date().getUTCFullYear()} penact digital
              &ndash;&nbsp;powered by penact s.r.o
            </p>
            <ul className="social">
              <li>
                <a
                  href="https://instagram.com/penactdigital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/penact-digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <ScrollDetect />
    </div>
  );
}

export default Layout;
