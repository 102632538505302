import { Link } from "react-router-dom";
import "./LogoCarousel.css";
import { ReactComponent as ArrowRight } from "../assets/svg/arrowright.svg";

const PortfolioCta = (props) => {
  return (
    <div className="logo-carousel-wrapper">
      <Link to={props.link} className="logo-carousel-title">
        <h4>{props.title}</h4>
        <ArrowRight />
      </Link>
    </div>
  );
};

export default PortfolioCta;
