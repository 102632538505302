import { useState, useEffect } from "react";
import PromoBox from "./PromoBox";
import { useTranslation } from "react-i18next";
import LogoGrid from "./LogoGrid";
import { ReactComponent as PinkArrow } from "../assets/svg/pink-arrow.svg";

const Portfolio = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const [cursorX, setCursorX] = useState();
  const [cursorY, setCursorY] = useState();

  const throttle = (callback, delay) => {
    let lastCall = 0;
    return function (...args) {
      const now = new Date().getTime();
      if (now - lastCall >= delay) {
        lastCall = now;
        callback(...args);
      }
    };
  };

  const handleMouseMove = throttle((e) => {
    if (window.innerWidth < 960) return;
    setCursorX(e.clientX);
    setCursorY(e.clientY);
  }, 200);

  window.addEventListener("mousemove", handleMouseMove);

  return (
    <>
      <div className="intro">
        <h1>{t("intro_title")}</h1>&nbsp;
        <h2>{t("intro_subtitle")}</h2>
      </div>

      <div className="promo-wrapper">
        <PromoBox
          slug="golf-lab"
          title={"Golf Lab"}
          promoType={"video"}
          source={"916886832"}
          tags={["branding", t("print")]}
        />
        <PromoBox
          slug="mamino"
          title={"mamino"}
          promoType={"image"}
          source={"Home_1.jpg"}
          tags={[t("apps"), "branding", "ux/ui design", t("dev")]}
        />
        <PromoBox
          slug="Circularo"
          title={"circularo"}
          promoType={"video"}
          source={"914930809"}
          tags={["motion design", t("print"), t("graphic_design")]}
        />
        <PromoBox
          slug="slavia"
          title={"HC Slavia Praha"}
          promoType={"gif"}
          source={"slavia-uvod.gif"}
          tags={[t("print"), t("graphic_design"), "motion design"]}
        />
        <PromoBox
          slug="domaci-elektrarna"
          title={"Domácí Elektrárna"}
          promoType={"image"}
          source={"03_domaci_01.png"}
          tags={["branding", t("print"), "ui/ux design", t("dev")]}
        />
        <PromoBox
          slug="profishot"
          title={"Profishot"}
          promoType={"video"}
          source={"914926259"}
          tags={["ux/ui design", t("dev")]}
        />
        <PromoBox
          slug="security-avengers"
          title={"Security Avengers"}
          promoType={"image"}
          source={"03_avengers_01.png"}
          tags={[t("print"), "ux/ui design", t("dev")]}
        />
        <PromoBox
          slug="unreal-studios"
          title={"Unreal Studios"}
          promoType={"image"}
          source={"03_unreal_09.png"}
          tags={["ux/ui design", t("dev")]}
        />
        <PromoBox
          slug="odlo"
          title={"odlo"}
          promoType={"image"}
          source={"H_6.jpg"}
          tags={["ux/ui design", t("dev")]}
        />
        <div
          className="enhancedCursor"
          style={{
            left: cursorX + "px",
            top: cursorY + "px",
          }}
        >
          <PinkArrow />
        </div>
      </div>
      <LogoGrid title={t("more_bout_us")} link="/about-us" />
    </>
  );
};

export default Portfolio;
