import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "./components/Layout";
import Portfolio from "./components/Portfolio";

// Pages
import AboutPage from "./pages/AboutPage";
import CareerPage from "./pages/CareerPage";
import Project from "./pages/Project.js";

const App = () => {
  const { t } = useTranslation();

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Portfolio />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/our-work" element={<Portfolio count={"all"} />} />
          <Route
            path="/our-work/security-avengers"
            element={
              <Project
                title={"Security Avengers"}
                slug={"security-avengers"}
                tags={[t("print"), "ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/unreal-studios"
            element={
              <Project
                title={"Unreal Studios"}
                slug={"unreal-studios"}
                tags={["ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/mamino"
            element={
              <Project
                title={"mamino"}
                slug={"mamino"}
                tags={[t("apps"), "branding", "ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/slavia"
            element={
              <Project
                title={"HC Slavia Praha"}
                slug={"slavia"}
                tags={[t("print"), t("graphic_design"), "motion design"]}
              />
            }
          />
          <Route
            path="/our-work/profishot"
            element={
              <Project
                title={"Profishot"}
                slug={"profishot"}
                tags={["ux/ui design", t("internal"), t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/car-wash"
            element={
              <Project
                title={"Car Wash"}
                slug={"car-wash"}
                tags={[t("print"), "branding", "ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/golf-lab"
            element={
              <Project
                title={"Golf Lab"}
                slug={"golf-lab"}
                tags={["branding", t("print")]}
              />
            }
          />
          <Route
            path="/our-work/odlo"
            element={
              <Project
                title={"odlo"}
                slug={"odlo"}
                tags={["ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/domaci-elektrarna"
            element={
              <Project
                title={"Domácí Elektrárna"}
                slug={"domaci-elektrarna"}
                tags={[t("branding"), t("print"), "ux/ui design", t("dev")]}
              />
            }
          />
          <Route
            path="/our-work/circularo"
            element={
              <Project
                title={"Circularo"}
                slug={"circularo"}
                tags={["motion design", t("print"), t("graphic_design")]}
              />
            }
          />
          <Route
            path="/our-work/sportec"
            element={
              <Project
                title={"sportec"}
                slug={"sportec"}
                tags={["branding", "ecommerce", t("dev")]}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
