import React from "react";
import { Link } from "react-router-dom";
import "./LogoCarousel.css";
import { ReactComponent as ArrowRight } from "../assets/svg/arrowright.svg";

const logos = [
  {
    src: require("../assets/logos/odlo.svg").default,
    alt: "odlo - logo",
    width: 96,
    height: 96,
  },
  {
    src: require("../assets/logos/slavia.svg").default,
    alt: "HC Slavia Praha - logo",
    width: 95,
    height: 96,
  },
  {
    src: require("../assets/logos/deloitte.svg").default,
    alt: "Deloitte Česká republika - logo",
    width: 213,
    height: 40,
  },
  {
    src: require("../assets/logos/westfield.svg").default,
    alt: "Westfield Chodov - logo",
    width: 180,
    height: 56,
  },
  {
    src: require("../assets/logos/tessina.svg").default,
    alt: "Tessina - logo",
    width: 119,
    height: 96,
  },
  {
    src: require("../assets/logos/profishot.svg").default,
    alt: "Profishot - logo",
    width: 194,
    height: 32,
  },
  {
    src: require("../assets/logos/relax.svg").default,
    alt: "Relax - logo",
    width: 209,
    height: 32,
  },
  {
    src: require("../assets/logos/treenut.svg").default,
    alt: "TREE NUT - logo",
    width: 174,
    height: 88,
  },
  {
    src: require("../assets/logos/golf-lab.svg").default,
    alt: "Golf Lab - logo",
    width: 120,
    height: 64,
  },
  {
    src: require("../assets/logos/security-avengers.svg").default,
    alt: "Security Avengers - logo",
    width: 185,
    height: 48,
  },
  {
    src: require("../assets/logos/bvv.svg").default,
    alt: "Veletrhy Brno - logo",
    width: 145,
    height: 56,
  },
  {
    src: require("../assets/logos/vsa.svg").default,
    alt: "Veřejná sportovní akademie - logo",
    width: 220,
    height: 48,
  },
  {
    src: require("../assets/logos/psychiatrie-ambulance.svg").default,
    alt: "Psychiatrie Ambulance - logo",
    width: 165,
    height: 61,
  },
  {
    src: require("../assets/logos/domaci-elektrarna.svg").default,
    alt: "Domácí Elektrárna - logo",
    width: 182,
    height: 48,
  },
  {
    src: require("../assets/logos/sportec.svg").default,
    alt: "Sportec - logo",
    width: 208,
    height: 54,
  },
  {
    src: require("../assets/logos/jka-brooklyn.svg").default,
    alt: "JKA Brooklyn - logo",
    width: 74,
    height: 74,
  },
];

const LogoCarousel = (props) => {
  return (
    <div className="logo-carousel-wrapper">
      <Link to={props.link} className="logo-carousel-title">
        <h4>{props.title}</h4>
        <ArrowRight />
      </Link>

      <div className="logo-grid">
        {logos.map((logo, index) => (
          <div key={index} className="grid-item">
            <img
              src={logo.src}
              alt={logo.alt}
              width={logo.width}
              height={logo.height}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
