import { useState, useRef } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./ProjectGallery.css";
import Slider from "react-slick";
import "../../assets/tools/slick.css";

const ProjectGallery = (props) => {
  const sliderRef = useRef(null);
  const slickBack = useRef(null);
  const slickNext = useRef(null);

  const [showLightbox, setLightboxToShow] = useState(false);

  const toggleLightbox = (index) => {
    setLightboxToShow((a) => !a);
    const timer = setTimeout(() => {
      sliderRef.current.slickGoTo(index);
    }, 100);
    return () => clearTimeout(timer);
  };

  const lightboxSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    cssEase: "ease-out",
    speed: 600,
    nextArrow: slickNext,
    prevArrow: slickBack,
    accessibility: true,
  };

  const parsedData = props.data[props.slug];
  const host = "https://penactdigital.imgix.net/";
  const imgixProps = "?auto=format,compress&w=1960&q=75&dpi=72";

  const slickContent = parsedData.map((item, i) => {
    if (item.type === "image") {
      return (
        <div className="slide" key={i}>
          <figure>
            <img
              className={item.givenClass}
              src={host + item.source + imgixProps}
              alt="lightbox content"
            />
          </figure>
        </div>
      );
    } else if (item.type === "gif") {
      return (
        <div className="slide" key={i}>
          <figure>
            <img src={host + item.source} alt="lightbox content" />
          </figure>
        </div>
      );
    } else if (item.type === "video") {
      return (
        <div className="slide" key={i}>
          <Vimeo
            video={item.source}
            loop
            muted={true}
            background={true}
            autoplay
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            className={`${item.givenClass} vimeoEmbed`}
          />
        </div>
      );
    } else {
      return false;
    }
  });

  return (
    <>
      <div className={"gallery " + props.slug}>
        {parsedData.map((item, i) => {
          if (item.type === "image") {
            return (
              <img
                src={host + item.source + imgixProps}
                alt={props.title}
                className={item.givenClass}
                key={i}
                loading="lazy"
                onClick={(event) => toggleLightbox(i)}
              />
            );
          } else if (item.type === "gif") {
            return (
              <img
                src={host + item.source}
                alt={props.title}
                className={item.givenClass}
                key={i}
                loading="lazy"
                onClick={(event) => toggleLightbox(i)}
              />
            );
          } else if (item.type === "video") {
            return (
              <Vimeo
                video={item.source}
                loop
                muted={true}
                background={true}
                autoplay
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                className={`${item.givenClass} vimeoEmbed`}
                key={i}
              />
            );
          }
          return false;
        })}
        ;
      </div>
      {showLightbox && (
        <div className="lightbox-overlay">
          <div className="lightbox">
            <button className="close" onClick={toggleLightbox}>
              <span></span>
            </button>
            <div className="navigation">
              <button
                title="Go to the previous slide"
                onClick={() => sliderRef?.current?.slickPrev()}
                className="go-back"
              >
                <img
                  src={require("../../assets/svg/arrowright.svg").default}
                  alt="arrow left icon"
                  width="39"
                  height="24"
                />
              </button>
              <button
                title="Go to the next slide"
                onClick={() => sliderRef?.current?.slickNext()}
                ref={slickNext}
                className="go-forth"
              >
                <img
                  src={require("../../assets/svg/arrowright.svg").default}
                  alt="arrow right icon"
                  width="39"
                  height="24"
                />
              </button>
            </div>
            <Slider
              className="lightbox-slider"
              {...lightboxSettings}
              ref={sliderRef}
            >
              {slickContent}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectGallery;
