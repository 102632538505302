import "./Loading.css";

const Loading = ({ title, givenClass }) => {
  const getLoadingClass = (e) => {
    if (e != null) {
      return e.split("/").pop();
    }
  };

  return (
    <div className={"loading-container-wrapper " + getLoadingClass(givenClass)}>
      <div className="loading-container">
        <h1 className="loading-title">{title}</h1>
      </div>
    </div>
  );
};

export default Loading;
